<template>
  <transition name="fade" appear>
    <div class="home">
      <iframe :src="url" frameborder="0" scrolling="no" width="770" height="600"></iframe>
      <!-- <div class="content">发布时间{{ time }} 本文被浏览了{{ number }}次</div> -->
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      url: "",
      time: "",
      number: "85988",
      code:"",
    };
  },
  methods: {
    getFamilyDetail(){
      var getData={};
      getData.code=this.code;
      this.$api.home.getFamilyDetail(getData).then((res) => {
        if (res.data.code === 200) {
          this.url = res.data.data.url;
          this.time = res.data.data.createTime;
        }
      });
    },
     //禁用F12
    forbidden(){
       document.onkeydown = function() {
        var e = window.event || arguments[0];
        if (e.keyCode == 123) {
            return false;
        }
    }
    },
  //禁用右键
    forRightClick(){
      document.oncontextmenu = function() {
        return false;
    }
    }
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "4");
    this.code=this.$route.query.code;
    this.getFamilyDetail();
    this. forbidden()
    this.forRightClick()
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  margin-top: 20px;
  background-color: white;
  .content {
    text-align: right;
    margin-right: 200px;
    font-size: 16px;
  }
}
</style>
